import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthData } from "../../utils/Redux_Auth/authSlice";
import "./LoginPage.css";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, Button, Backdrop, CircularProgress } from "@mui/material";


const LoginPage = ({ onLoginSuccess }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;
    if (!email || !password) {
      setErrorMessage("Please enter credentials to login.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://insight.quin.design/v1/auth/login",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.data.success && response.headers.token) {
        dispatch(setAuthData({ token: response.headers.token }));
        onLoginSuccess(response.headers.token);
      } else {
        setErrorMessage(
          response.data.message || "An error occurred during login."
        );
      }
    } catch (err) {
      setErrorMessage(
        err.response?.data?.message || "Network error or server is unreachable."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}> <CircularProgress /></Backdrop>
      <div className="image-container">
        <img
          src="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/c6e7087b-dafb-4421-a415-45e3a8d8058c.png"
          alt="Quin Insights Logo"
        />

        <div className="logo">
          <img
            src="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/9408cfa6-2913-4cb4-96b3-a1965c26223e.png"
            alt="Logo"
          />
        </div>

        <div className="image-description">
          Smart about <span style={{ color: "#4EF8E8" }}>SAFETY</span>
        </div>
      </div>
      <section className="code-container">
        <form onSubmit={handleSubmit} className="login-form">
          <header className="login-header">
            <div
              style={{
                color: "black",
                fontSize: 32,
                fontFamily: "Proxima Nova",
                fontWeight: "300",
                wordWrap: "break-word",
              }}
            >
              Welcome to
            </div>
            <div
              style={{
                color: "black",
                fontSize: 44,
                fontFamily: "Proxima Nova",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              Quin Insights
            </div>
            <p>Sign in to your Account</p>
          </header>
          <TextField
            type="email"
            name="email"
            label="Email"
            value={formData.email}
            onChange={handleChange}
            autoComplete="off"
            className="email-input" // Ensure this class is correctly applied
            sx={{ margin: "1.5%" }}
          />
          <br />

          <TextField
            type={showPassword ? "text" : "password"}
            name="password"
            label="Password"
            value={formData.password}
            onChange={handleChange}
            autoComplete="new-password"
            className="password-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="input-adornment" sx={{ background: "none" }}>
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ margin: "1.5%" }}
          />

          <div className="button-container">
            <Button type="submit">Log in</Button>
          </div>
          {errorMessage && (
            <p className="error-message">
              <strong>{errorMessage}</strong>
            </p>
          )}
        </form>
      </section>
    </div>
  );
};

export default LoginPage;
