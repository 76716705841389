import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import './MultiActionAreaCard.css'; // Import CSS file for card styles

const MultiActionAreaCard = ({ title, description, image, isSelected }) => {
  return (
    <Card className={`multi-action-card ${isSelected ? 'selected' : ''}`}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="150"
          image={image}
          alt="card image"
          className="card-image"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default MultiActionAreaCard;
