import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Homepage.css";
import MultiActionAreaCard from "./MultiActionAreaCard/MultiActionAreaCard"; // Import the MultiActionAreaCard component
import PerformanceUI from "./QuinPerformanceCards/performanceUI";
import QuinRideCards from "./QuinRideCards/QuinRideCards";

const Homepage = () => {
  const location = useLocation();
  const selectedItem = location.pathname.split("/")[2];
  const permissions = useSelector((state) => state.auth.permissions);
  const hasPermission = (perm) => permissions.includes(perm);

  const [isQuinPerformanceSelected, setIsQuinPerformanceSelected] =
    useState(false);

  const [isQuinRideSelected, setIsQuinRideSelected] = useState(false);

  return (
    <div className="homepage-container">
      <h1 style={{ textAlign: "center", fontFamily: "poppins" }}>
        Welcome to Quin Insights
      </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap", // Added flexWrap to wrap items to next row
          }}
        >
          {/* {hasPermission("anamolies_data") && (
            <Link to="/admin/anamolies-data" style={{ textDecoration: "none" }}>
              <MultiActionAreaCard
                title="Anomalies"
                description="Description of anomalies data"
                image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/72b27782-e696-4e92-b4cb-2a38f8840cca.png"
                isSelected={selectedItem === "anamolies-data"}
              />
            </Link>
          )} */}

          {hasPermission("app_stats") && (
            <Link to="/admin/dashboard" style={{ textDecoration: "none" }}>
              <MultiActionAreaCard
                title="Application Stats"
                description="Statistical data and metrics generated by a software application."
                image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/7dfc9a78-6def-487f-b33d-50b31561034d.png"
                isSelected={selectedItem === "dashboard"}
              />
            </Link>
          )}

          {/* {hasPermission("blueimpact_analyzer") && (
            <Link to="/admin/ble-analyzer" style={{ textDecoration: "none" }}>
              <MultiActionAreaCard
                title="Bluetooth Testing"
                description="Evaluation and verification of Bluetooth-enabled devices or software."
                image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/d14eed03-4c17-4e36-8730-a1f0f8ae6437.png"
                isSelected={selectedItem === "ble-analyzer"}
              />
            </Link>
          )} */}

          {hasPermission("crash_details") && (
            <Link to="/admin/crash" style={{ textDecoration: "none" }}>
              <MultiActionAreaCard
                title="Crash Details"
                description="Comprehensive information collected when a software application crashes."
                image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/e5b0b2b2-7222-4e12-ab97-8147444c4b1a.png"
                isSelected={selectedItem === "crash"}
              />
            </Link>
          )}

          {/* {hasPermission("email_signature") && (
            <Link
              to="/admin/email-signature"
              style={{ textDecoration: "none" }}
            >
              <MultiActionAreaCard
                title="Email Signature"
                description="Personalized block of text, images, or links appended to the end of an email message."
                image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/9ce57e8c-8c03-44e9-97d3-0129a59e615f.png"
                isSelected={selectedItem === "email-signature"}
              />
            </Link>
          )} */}

          {hasPermission("user_insights") && (
            <Link to="/admin/user-insights" style={{ textDecoration: "none" }}>
              <MultiActionAreaCard
                title="User Insights"
                description="Qualitative and quantitative information gathered from users of a product or service."
                image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/a6e24c1c-0baa-4a20-bb84-b5f40ec67b75.png"
                isSelected={selectedItem === "user-insights"}
              />
            </Link>
          )}

          {hasPermission("quin_performance") && (
            <div
              style={{ position: "relative" }}
              onClick={() =>
                setIsQuinPerformanceSelected(!isQuinPerformanceSelected)
              }
            >
              <Link to="/admin/performance" style={{ textDecoration: "none" }}>
                <MultiActionAreaCard
                  title="Quin Performance"
                  description="Quin Performance - Performance metrics associated with a product, service, or entity named 'Quin'."
                  image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/b616fb64-2947-44bf-ba04-4060d63097a6.png"
                  isSelected={selectedItem === "quin-performance"}
                />
                {isQuinPerformanceSelected && (
                  <PerformanceUI selectedItem={selectedItem} />
                )}
              </Link>
            </div>
          )}

          {hasPermission("quin_ride") && (
            <div
              onClick={() => setIsQuinRideSelected(!isQuinRideSelected)}
              style={{ position: "relative" }}
            >
              <Link to="/admin/quin_ride" style={{ textDecoration: "none" }}>
                <MultiActionAreaCard
                  title="Quin 2.9"
                  description="Upgraded Version of 2.0 Quin."
                  image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/7c5f796e-b002-4b26-a253-46a35d7d648f.png"
                  isSelected={selectedItem === "quinride"}
                  onClick={() => setIsQuinRideSelected(!isQuinRideSelected)}
                />
              </Link>

              {isQuinRideSelected && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    zIndex: 0,
                  }}
                >
                  <QuinRideCards selectedItem={selectedItem} />
                </div>
              )}
            </div>
          )}
          {hasPermission("hardware_sensors") && (
            <Link
              to="/admin/hardware_sensors"
              style={{ textDecoration: "none" }}
            >
              <MultiActionAreaCard
                title="hardware_sensors"
                description="Configurable Sensors."
                image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/1a50a90b-5da3-4001-a21c-5d52b63ed3d7.jpeg"
                isSelected={selectedItem === "hardware_sensors"}
              />
            </Link>
          )}

          {hasPermission("admin_settings") && (
            <Link to="/admin/settings" style={{ textDecoration: "none" }}>
              <MultiActionAreaCard
                title="Settings"
                description="Configurable options within a software application or system."
                image="https://d3k81ch9hvuctc.cloudfront.net/company/SCpWft/images/1a50a90b-5da3-4001-a21c-5d52b63ed3d7.jpeg"
                isSelected={selectedItem === "settings"}
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Homepage;
