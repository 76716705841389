import React from 'react';
import './ApiErrorPopup.css';
import { Button } from '@mui/material';

const ApiErrorPopup = ({ code, message, onClose }) => {
  const errorDetails = {
    '401': {
      title: 'Unauthorized Access',
      gif: 'https://giphy.com/embed/YQitE4YNQNahy',
    },
    '404': {
      title: 'Not Found',
      gif: 'https://giphy.com/embed/y4E6VumnBbIfm',
    },
    'default': {
      title: 'Error Occurred',
      gif: 'https://giphy.com/embed/r3xBH1FXWz0h55CVtj',
    },
  };

  const { title, gif } = errorDetails[code] || errorDetails['default'];

  return (
    <div className="popup-background">
      <div className="popup-container">
        <h2>{title}</h2>
        <iframe src={gif} width="480" height="480" frameBorder="0" className="giphy-embed" allowFullScreen title="Error GIF"></iframe>
        <p>{message}</p>
        <Button onClick={onClose} className="close-button">Close</Button>
      </div>
    </div>
  );
};

export default ApiErrorPopup;
