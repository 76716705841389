import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import { useAuth } from './utils/AuthContext';

const HeaderWrapper = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const showHeader = isAuthenticated && location.pathname !== "/login";

  return showHeader ? <Header /> : null;
};

export default HeaderWrapper;

